<template id="main-page">
  <v-ons-page>
    <div class="main-content">
      <v-ons-card class="pace-input" style="position: relative;" v-bind:class="{ 'hide-in-mobile': favoritesVisibleInMobile }">
        <div class="title">
          <span>Pace calculator</span>

          <v-ons-button modifier="quiet" class="favorite" @click="favorite()">
            <!--          <v-ons-icon icon="ion-ios-star-outline"></v-ons-icon>-->
            <v-ons-icon icon="ion-ios-star"></v-ons-icon>
          </v-ons-button>
        </div>

        <div class="content">
          <v-ons-segment class="measure-options" :index.sync="unit">
            <button @click="setUnit(0)">Km</button>
            <button @click="setUnit(1)">Mi</button>
          </v-ons-segment>
          <br />
          <br />

          <v-ons-segment class="distance-options" :index.sync="distanceKey">
            <button
              v-for="(value, index) in DISTANCES"
              :key="index"
              @click="setDistance(value.distance)"
            >
              {{ value.label }}
            </button>
          </v-ons-segment>

          <br />
          <br />
          <v-ons-range
            v-model="pace"
            @drag="drag"
            @change="dragEnd"
            style="width: 100%"
            min="120"
            max="500"
          ></v-ons-range>
          <br />
          <br />
        </div>

        <br />
        <br />
        <v-ons-segment class="mobile-tabs show-in-mobile" :index.sync="mobileVisibilityTab">
          <button @click="showFavoritesInMobile(false)">
            <v-ons-icon icon="fa-cog" size="35px"></v-ons-icon>
          </button>
          <button @click="showFavoritesInMobile(true)">
            <v-ons-icon icon="ion-ios-star" size="35px"></v-ons-icon>
          </button>
        </v-ons-segment>

      </v-ons-card>

      <v-ons-card class="history" v-bind:class="{ 'hide-in-mobile': !favoritesVisibleInMobile }">
        <div v-if="favorites.length" style="position: relative;">
          <v-ons-list>
            <v-ons-list-header>Favorites</v-ons-list-header>
            <v-ons-list-item
              v-for="(favorite, index) in favorites"
              :key="index"
            >
              <v-ons-button modifier="quiet" @click="pick(favorite)">{{
                favorite | summaryStr
              }}</v-ons-button>
              <v-ons-button
                style="flex: auto; text-align: right"
                modifier="quiet"
                @click="remove(index)"
              >
                <v-ons-icon icon="ion-ios-trash"></v-ons-icon>
              </v-ons-button>
            </v-ons-list-item>
          </v-ons-list>
          <br/>
          <br/>
          <br/>
          <v-ons-segment class="mobile-tabs show-in-mobile" :index.sync="mobileVisibilityTab">
            <button @click="showFavoritesInMobile(false)">
              <v-ons-icon icon="fa-cog" size="35px"></v-ons-icon>
            </button>
            <button @click="showFavoritesInMobile(true)">
              <v-ons-icon icon="ion-ios-star" size="35px"></v-ons-icon>
            </button>
          </v-ons-segment>
        </div>

        <div v-else>
          <v-ons-icon icon="ion-ios-walk" size="30px"></v-ons-icon>
          <p>
            Looks like you haven't saved anything in your favorites. Add your
            first one by clicking
            <v-ons-icon icon="ion-ios-star"></v-ons-icon> to add it to the list
          </p>
        </div>
      </v-ons-card>

      <v-ons-card class="pace-calc">
        <br />
        with a pace of <strong>{{ this.pace | paceToString }}</strong> your
        result will be <strong>{{ this.finish | paceToString }}</strong>
        <br />

        <v-ons-list class="list">
          <v-ons-list-header>
            <span>Distance {{ this.unit === 0 ? "Km" : "Mi" }}</span>
            <span style="float: right">Time</span>
          </v-ons-list-header>
          <v-ons-list-item
            v-for="(value, key, index) in intervals"
            :key="index"
          >
            <span>{{ key | roundDistance }}</span>
            <span style="text-align: right">{{ value | paceToString }}</span>
          </v-ons-list-item>
        </v-ons-list>
      </v-ons-card>
    </div>
  </v-ons-page>
</template>

<style>
.favorite {
  float: right;
}

.list span {
  font-size: smaller;
}

.list .list-item__center {
  padding: 0;
  min-height: 20px;
}
.main-content {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-self: center;

  max-width: 1200px;
  margin: 0 auto;
}

.measure-options,
.distance-options {
  min-width: 300px;
}

.list-item__center span,
.pace-input,
.history {
  flex-grow: 1;
}

.history,
.pace-input {
  min-width: 300px;
  max-width: 600px;
}

.pace-calc {
  flex-grow: 1;
}

@media screen and (max-width: 600px) {
  .hide-in-mobile {
    display: none !important;
  }
}

@media screen and (min-width: 600px) {
  .show-in-mobile {
    visibility: hidden;
  }
}

.mobile-tabs {
  left: 0;
  right: 0;
  position: absolute;
  bottom: 0;
}

.mobile-tabs button .segment__button {
  min-height: 50px;
}

</style>

<script>
import Vue from "vue";

const DISTANCES = [
  { distance: 5000, label: "5k" },
  { distance: 10000, label: "10k" },
  { distance: 21097, label: "½ Marathon" },
  { distance: 42195, label: "Marathon" },
];

const adjustedStepCount = function (unit, distance) {
  const adj = unit === 1 ? 0.621371 : 1;
  return (distance * adj) / 1000;
};

const summaryStr = function (favorite) {
  const ob = DISTANCES[favorite.distanceKey] ?? {};
  const total = paceToString(
    favorite.pace * adjustedStepCount(favorite.unit, ob.distance)
  );
  const pace =
    paceToString(favorite.pace) +
    (parseInt(favorite.unit, 10) === 1 ? "Mi" : "Km");

  return ob ? `${ob?.label} ${pace} ${total}` : "";
};

const roundDistance = function (distance) {
  return Math.round(distance * 100) / 100;
};

const paceToString = function (pace) {
  const sec = Math.round(pace % 60);
  let min = Math.floor(pace / 60);
  const hour = Math.floor(min / 60);
  min = min - hour * 60;
  const pre = hour ? `${hour.toString(10).padStart(2, "0")}:` : "";
  const str = `${pre}${min.toString(10).padStart(2, "0")}:${sec
    .toString(10)
    .padStart(2, "0")}`;
  return str;
};

export default {
  data() {
    return {
      favoritesVisibleInMobile: false,
      mobileVisibilityTab: 0,
      unit: 0,
      distanceKey: 2,
      distance: 21000,
      pace: 5 * 60,
      total: 60 * 60 * 2,
      finish: 0,
      intervals: {},
      favorites: JSON.parse(localStorage.getItem("favorites") ?? "[]"),
    };
  },

  created() {
    this.DISTANCES = DISTANCES;
  },

  methods: {
    setDistance(dist) {
      this.distance = dist;
      this.update();
      this.$ga.event("options", "select", "distance", dist);
    },

    updateStorage() {
      localStorage.setItem("favorites", JSON.stringify(this.favorites));
      this.favorites = JSON.parse(localStorage.getItem("favorites") ?? "[]");
    },

    favorite() {
      const newOne = {
        unit: this.unit,
        distanceKey: this.distanceKey,
        pace: this.pace,
        finish: this.finish,
      };
      this.favorites.push(newOne);
      this.updateStorage();
      this.$ga.event("favorite", "add", null, null);
    },

    remove(index) {
      this.favorites.splice(index, 1);
      this.updateStorage();
      this.$ga.event("favorite", "remove", null, index);
    },

    pick(favorite) {
      const DISTANCES = [5000, 10000, 21097, 42195];
      this.unit = favorite.unit;
      this.distanceKey = favorite.distanceKey;
      this.pace = favorite.pace;
      this.finish = favorite.finish;
      this.distance = DISTANCES[favorite.distanceKey];
      this.update();
    },

    showFavoritesInMobile(visible) {
      this.$ga.event("options", "select", "favoritesVisibility", visible);
      this.favoritesVisibleInMobile = visible;
    },

    setUnit(unit) {
      this.unit = unit;
      this.$ga.event("options", "select", "unit", unit);
      this.update();
    },

    extracted: function (pad, time) {
      let foo = [];
      const targ = 1 + pad * 2;
      for (let o = 0; o < targ; o++) {
        const ind = o - pad;
        foo.push(time + ind * 5);
      }
      return foo;
    },

    drag() {
      this.update(true);
    },

    dragEnd() {
      this.update(true);
    },

    update(skipDel = false) {
      // no update when unit changed
      // keep pace when distance changed => update total
      // update pace when total changed
      if (!skipDel) {
        for (const [key] of Object.entries(this.intervals)) {
          Vue.delete(this.intervals, key);
        }
        this.intervals = {};
      }

      /*
      const pad = 1;
      console.log(pad);
*/

      const ks = adjustedStepCount(this.unit, this.distance);

      for (let i = 1; i < ks; i++) {
        const time = this.pace * i;
        Vue.set(this.intervals, i, time);
      }

      this.intervals[ks] = [ks * this.pace];
      this.finish = ks * this.pace;
    },
  },

  filters: {
    roundDistance: roundDistance,
    paceToString: paceToString,
    summaryStr: summaryStr,
  },

  mounted() {
    this.setDistance(21097);
  },
};
</script>
