import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";

// Webpack CSS import
import "onsenui/css/onsenui.css";
import "onsenui/css/onsen-css-components.css";

import VueOnsen from "vue-onsenui"; // This imports 'onsenui', so no need to import it separately
import VueAnalytics from "vue-analytics";

Vue.use(VueOnsen); // VueOnsen set here as plugin to VUE. Done automatically if a call to window.Vue exists in the startup code.
Vue.config.productionTip = false;

Vue.use(VueAnalytics, {
  id: "UA-113368609-4",
});

new Vue({
  render: (h) => h(App),
}).$mount("#app");
